import React, { useState } from 'react';
import {
  bool, func, oneOfType, number, shape, string
} from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import {
  Modal, ModalBody, Typography, ModalHeader, Button
} from '@one-thd/sui-atomic-components';
import * as actions from './share.action';

const FRAME_HEIGHT = '436';
const FRAME_WIDTH = '626';

const ShareToPinterest = ({
  closeOverlays,
  data,
  hrefPath,
  itemId,
  pinterestOverlayOpen,
  pinterestTitle
}) => {
  const [allImages] = useState([...document.images]);

  const onMouseEnter = (evt) => {
    let event = evt;
    event.currentTarget.querySelector('#pinterest-button-image').classList.add('sui-opacity-50');
    event.currentTarget.querySelector('#pinterest-button-icon').classList.remove('sui-hidden');
  };

  const onMouseLeave = (evt) => {
    let event = evt;
    event.currentTarget.querySelector('#pinterest-button-image').classList.remove('sui-opacity-50');
    event.currentTarget.querySelector('#pinterest-button-icon').classList.add('sui-hidden');
  };

  const onPinImgClick = (event) => {
    const url = actions.getUrl(
      hrefPath,
      { fiscalYear: data?.product?.info?.fiscalYear },
        data?.product?.info?.productDepartment,
        data?.product?.info?.classNumber,
        itemId
    );
    const pinterestUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${(encodeURIComponent(event.target.src))}&description=${encodeURIComponent(pinterestTitle)}`; // eslint-disable-line max-len
    window.open(pinterestUrl, 'pinterest_share_dialog', `width=${FRAME_WIDTH},height=${FRAME_HEIGHT}`);
  };

  const getImagesForOverlay = () => {
    return (
      <div>
        {(allImages || []).filter((img) => img.width >= 145 && img.height >= 100).map((img, index) => {
          const { src, alt, height, width } = img;
          return (
            <Button
              onMouseOver={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={(event) => onPinImgClick(event, data)}
              onFocus={() => {}}
              key={index}
              className="pinterest-button"
              variant="ghost"
            >
              <span className="sui-border-primary sui-border-1 sui-border-solid hover:sui-shadow-md
              hover:sui-border-info hover:sui-border-2 sui-relative sui-overflow-hidden sui-float-left
              sui-ml-2 sui-mr-3 sui-mb-3 sui-w-48 sui-h-48 "
              >
                <div id="pinterest-button-image">
                  <Image
                    alt={alt}
                    className="sui-w-48 sui-h-48 sui-overflow-hidden hover:sui-scale-100"
                    height="190"
                    src={src}
                    width="185"
                  />
                </div>
                <div
                  id="pinterest-button-icon"
                  data-testid="pinterest-button-icon"
                  className="pinterest-button__icon sui-absolute sui-hidden sui-float-none sui-scale-150"
                />
                <span className="sui-inline-block sui-absolute sui-bg-strong
                sui-leading-6 sui-pt-1 sui-opacity-70 sui-text-center sui-overflow-hidden
                sui-h-5 sui-text-primary sui-right-0 sui-left-0 sui-bottom-0"
                >
                  <Typography variant="body-xs" weight="bold">
                    {height === 300 ? 'Preferred Image' : `${height}x${width}`}
                  </Typography>
                </span>
              </span>
            </Button>
          );
        })}
      </div>
    );
  };
  return (
    <Modal
      open={pinterestOverlayOpen}
      onClose={closeOverlays}
    >
      <ModalHeader onClose={closeOverlays}>
        <div className="sui-inline-flex">
          <span
            className="sui-bg-no-repeat sui-inline-block
          sui-relative thd-sharebelt-icons--pinterest sui-float-none
          sui-scale-100 !sui-top-0"
          />
          Pin It to Pinterest
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="sui-overflow-x-hidden sui-overflow-y-auto sui-pt-3">
          {getImagesForOverlay()}
        </div>
      </ModalBody>
    </Modal>
  );
};

ShareToPinterest.propTypes = {
  closeOverlays: func,
  data: shape({}),
  hrefPath: string,
  itemId: oneOfType([string, number]),
  pinterestOverlayOpen: bool,
  pinterestTitle: string
};

ShareToPinterest.defaultProps = {
  closeOverlays: null,
  data: null,
  hrefPath: '',
  itemId: null,
  pinterestOverlayOpen: false,
  pinterestTitle: null
};

export default ShareToPinterest;
